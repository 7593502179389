import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Peter Laseinde</h2>
        <p><a href="mailto:laolu.peter@gmail.com">laolu.peter@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Only few people enjoy describing themselves however I will pick my
        top 5 strengths according to Gallup&apos;s strength finder assessment to describe myself.
      </p>
      <p><b>Achiever:</b> I constantly find the the need for achievement. Every new day
        starts at zero and ends with something tangibly achieved.
      </p>
      <p><b>Learner:</b> I crave for new knowledge.</p>
      <p><b>Strategic:</b> I sort through the clutter to find teh best route.</p>
      <p><b>Analytical:</b> I easily search for patterns and connections in data.</p>
      <p><b>Competition:</b> I compete to win.</p>

      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Click to see my resume</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Peter Laseinde, <Link to="/">laolu.peter@gmail.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
